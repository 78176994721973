import Avatar from '@mui/material/Avatar';
// components
import { NETWORK_ICON } from '../../../config';


// ----------------------------------------------------------------------
const ImgIcon = (name) => (
  <Avatar src={`/icons/${name}`} sx={{ width: 22, height: 22 }} alt="" />
);

const ICONS = {
  main: ImgIcon(NETWORK_ICON),
};

const sidebarConfig = [
  {
    subheader: '',
    items: [
      // TERRA
      // ----------------------------------------------------------------------
      {
        title: 'Terra',
        path: '/terra',
        icon: ICONS.main,
        expandMenu: true,
        children: [
          { title: 'Supply', path: '/terra/supply' },
          { title: 'Stats', path: '/terra/stats' },
          { title: 'Validators', path: '/terra/validators' },
          { title: 'Community Pool', path: '/terra/communitypool' },
          { title: 'Oracle Pool', path: '/terra/oraclepool', isNew: true },
          { title: 'Wallets', path: '/terra/wallets' },
          { title: 'Transactions', path: '/terra/transactions' },
          { title: 'Decentralization', path: '/terra/decentralization' },
          { title: 'Tools', path: '/terra/tools' },
        ],
      },
      // ANCHOR
      // ----------------------------------------------------------------------
      // { title: 'Anchor', path: '/anchor', icon: ICONS.anchor },
    ],
  },
];

export default sidebarConfig;
